<template>
    <div class="content">
        <div class="content__title text_40_b tac">Комплекс услуг ТО</div>
        <div class="content__button flex">
            <a href="https://n787595.yclients.com/" class="button button--blue">
                Записаться на техосмотр
            </a>
        </div>
        <div class="form">
            <div v-if="!sended" class="form__header pd flex">
                <div class="text_24_b">
                    Заказать обратный звонок
                </div>
                <div>
                    Заполните форму и технический эксперт перезвонит Вам.
                </div>
            </div>

            <transition name="fade" mode="out-in">
                <div v-if="!sended" key="1" class="form__content pd">
                    <div class="form-group grid">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">
                                Ваше имя:
                                <span class="error">{{
                                    errorMessages.name
                                }}</span>
                            </div>
                            <div class="form-field__component">
                                <input
                                    class="form-group__input"
                                    type="text"
                                    required
                                    v-model="userName"
                                />
                            </div>
                        </div>
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">Ваш телефон:</div>
                            <div class="form-field__component">
                                <vue-phone-number-input
                                    no-flags
                                    :preferred-countries="['RU']"
                                    :translations="phoneTranslations"
                                    v-model="phone"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group grid">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">
                                Выберите дату и время:
                            </div>
                            <div class="form-field__component">
                                <date-range-picker
                                    ref="picker"
                                    opens="left"
                                    singleDatePicker="single"
                                    :timePicker="true"
                                    :timePicker24Hour="true"
                                    :showWeekNumbers="false"
                                    :showDropdowns="true"
                                    :autoApply="false"
                                    :ranges="false"
                                    v-model="dateRange"
                                    :linkedCalendars="false"
                                    :locale-data="locale"
                                >
                                    <template
                                        v-slot:input="picker"
                                        style="min-width: 350px;"
                                    >
                                        {{
                                            picker.startDate.toLocaleString(
                                                "ru",
                                                {
                                                    weekday: "long",
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }
                                            )
                                        }}
                                    </template>
                                </date-range-picker>
                            </div>
                        </div>
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">
                                Как с вами связаться:
                            </div>
                            <div class="form-field__component">
                                <dropdown
                                    class="my-dropdown-toggle"
                                    :options="callType"
                                    :selected="selectedCallType"
                                    :placeholder="'Выберите'"
                                    :closeOnOutsideClick="true"
                                    @updateOption="selectedCallType = $event"
                                >
                                </dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="form-group form-group--lg-offset">
                        <div class="form-field form-field--centered ">
                            <div class="button button--blue" @click="submit()">
                                Отправить запрос
                                <div
                                    class="loader"
                                    v-if="requestStatus === 'loading'"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="text_24_b tac submit-error"
                        v-if="requestStatus === 'error'"
                    >
                        Произошла ошибка отправки формы, <br />позвоните по
                        номеру телефона
                        <a href="tel:+78482713013">8 (8482) 713 - 013</a>
                    </div>
                </div>
                <div class="form__content pd" key="2" v-else>
                    <div class="text_24_b tac">
                        Заявка отправлена, менеджер свяжется с вами в ближайшее
                        время
                    </div>
                </div>
            </transition>
            <div class="form__footer text_12">
                Отправляя данную форму, я даю согласие на
                <a href="https://arlan-auto.ru/informaciya" target="_blank"
                    >обработку персональных данных</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import dropdown from "vue-dropdowns";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
    name: "Index",
    components: {
        DateRangePicker,
        dropdown,
    },
    data() {
        return {
            requestStatus: "",
            sended: false,
            errorMessages: {
                name: "",
            },
            callType: [
                {
                    name: "Позвоните мне",
                },
                {
                    name: "Напишите мне в Telegram",
                },
                {
                    name: "Напишите мне в WhatsApp",
                },
                {
                    name: "Напишите мне в Viber",
                },
            ],
            selectedCallType: {
                name: "Позвоните мне",
            },
            userName: "",
            dateRange: { startDate: new Date(), endDate: new Date() },
            phone: "",
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "Номер телефона",
                example: "Например:",
            },
            locale: {
                direction: "ltr",
                format: "yyyy-mm-dd HH:mm:ss",
                separator: " - ",
                applyLabel: "ВЫбрать",
                cancelLabel: "Отмена",
                weekLabel: "Н",
                customRangeLabel: "Custom Range",
                daysOfWeek: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                monthNames: [
                    "Янв",
                    "Фев",
                    "Март",
                    "Апр",
                    "Май",
                    "Июн",
                    "Июл",
                    "Авг",
                    "Сент",
                    "Окт",
                    "Ноя",
                    "Дек",
                ],
                firstDay: 1,
            },
            options: {
                // defaults
                timeFormat: {
                    locale: "ru",
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: false,
                },
                display: {
                    maxWidth: 400,
                },
            },
        };
    },
    methods: {
        showError(field) {
            let message = "заполните это поле";
            if (field == "name") {
                message = "заполните имя";
            }
            this.errorMessages[field] = message;
        },
        async submit() {
            if (!this.userName) {
                this.showError("name");
                return false;
            }

            this.requestStatus = "loading";
            axios.defaults.withCredentials = true;
            axios
                .get("https://api.arlan-auto.ru/api/v1/csrf_token")
                .then(() => {
                    axios
                        .post(
                            "https://api.arlan-auto.ru/api/v1/amo/create/lead/tehosmotr63pro",
                            {
                                phone: "+7" + this.phone,
                                name: this.userName,
                                datetime: this.$refs.picker.rangeText,
                                how_to_contact: this.selectedCallType.name,
                            }
                        )
                        .then(() => {
                            this.sended = true;
                            this.requestStatus = "";
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
};
</script>
<style lang="less">
@import url("../../assets/less/vars.less");
.tac {
    text-align: center;
}
.content__button {
    a {
        text-decoration: none;
    }
}
.form__header.pd {
    align-items: center;
    padding: 56px 64px 0;
    .text_24_b {
        @media @sm {
            font-size: 22px !important;
            font-weight: 700 !important;
            margin-bottom: 20px;
        }
    }
    @media @sm {
        padding: 26px 24px 0;
    }
}
.form-field__component {
    .btn-group {
        margin: 0;
    }
}
.form__content {
    .grid {
        grid-template-columns: repeat(2, 1fr);
        @media @sm {
            grid-template-columns: repeat(1, 1fr);
            gap: 26px;
        }
    }
}
.form__content.pd {
    padding: 48px 64px 56px;
    @media @sm {
        padding: 24px 28px;
    }
}
</style>
